@import "~@/erp/styles/variables/variables.scss";




























































































































































































































































































































* {
  background: white;
}

.td-text {
  word-break: break-all;
  text-align: left;
}

.print-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;

  .print+.print {
    margin-top: 50px;
  }
}

.print {
  height: 100%;
  /* width: 1280px; */
  margin: 0 auto;
  background: white;

  h1 {
    position: relative;
    margin: 0 auto;
    height: 90px;
    width: 1280px;
    line-height: 100px;
    text-align: center;
    font-size: 28px;

    img {
      height: 120px;
      position: absolute;
      top: 76px;
      right: 9px;
    }
  }

  .container {
    height: auto;
    padding: 0 5px 20px 0;
    overflow-y: auto;

    .box {

      /* margin-bottom: 10px; */
      .top {
        /* display: flex;
        > div {
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin-right: 20px;
          span {
            font-weight: 400;
          }
        } */
      }

      .base-info {
        display: flex;

        >div {
          border: 1px solid rgba(234, 235, 237, 1);
          width: calc(50% - 8px);
          padding: 10px;
          line-height: 26px;

          em {
            font-weight: 500;
            font-style: normal;
          }
        }

        >div:last-child {
          margin-left: 16px;
        }
      }
    }

    .wrapper-img {
      overflow: hidden;

      img {
        float: left;
        width: 120px;
        height: 120px;
        margin-right: 10px;
        margin-bottom: 10px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }

  .custom-table thead th {
    min-width: 90px;
  }

  .custom-table p {
    text-align: left;
  }
}
